import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import IDALayout from "../../../components/layouts/layout-ida"
import Seo from "../../../components/seo"

import EopCalloutCards from "../../../components/shared/generic/eop-callout-cards"
import akbcares from "../../../images/AkebiaCares-logo.png"

// import VideoWithPlayButton from "../../../components/shared/brightcove-scroll-bttn";
import VideoWithPlayButton from "../../../components/shared/youtube-scroll-bttn";
import videoPoster from "../../../images/ida/moa/IDA_MOA_thumbnail.png"
import TranscriptAccordion from "../../../components/shared/transcript-accordion"

import DosingIconOne from "../../../components/ida/dosing-icon-1"
import DosingIconTwo from "../../../components/ida/dosing-icon-2"
import DosingIconThree from "../../../components/ida/dosing-icon-3"
import DosingIconFour from "../../../components/ida/dosing-icon-4"
import CalloutIcon1 from "../../../images/icons/icon-callout-ida-efficacy.png"
import CalloutIcon2 from "../../../images/icons/icon-callout-ida-safety.png"

const IdaMoaPage = ({ location }) => (
  <IDALayout location={location}
    locationHostName={location.hostname}
    jobCode="PP-AUR-US-1408 (v2.0)"
    jobCodeDate="03/22"
    references={[
      <>Webster AC, Nagler EV, Morton RL, Masson P. Chronic kidney disease. <em>Lancet</em>. 2017;389(10075):1238-1252.</>,
      <>AURYXIA<sup>®</sup> [Package Insert]. Cambridge, MA: Akebia Therapeutics, Inc.; 2021.</>,
      <>Ganz T, Bino A, Salusky IB. Mechanism of action and clinical attributes of Auryxia<sup>&reg;</sup> (Ferric Citrate). <em>Drugs</em>. 2019;79(9):957-968.</>,
      <>Yagil Y, Fadem SZ, Kant KS, et al. Managing hyperphosphatemia in patients with chronic kidney disease on dialysis with ferric citrate: latest evidence and clinical usefulness. <em>Ther Adv Chronic Dis</em>. 2015;6(5):252-263.</>,
      <>Kalantar-Zadeh K, Streja E, Miller JE, Nissenson AR. Intravenous iron versus erythropoiesis-stimulating agents: friends or foes in treating chronic kidney disease anemia? <em>Adv Chronic Kidney Dis</em>. 2009;16(2):143-151.</>,

    ]}>
    <Helmet>
      <html lang="en" />
      <link rel="canonical" href="https://www.auryxiahcp.com/iron-deficiency-anemia/mechanism-of-action" />
      <body id="ida-hcp-moa" className="ida" />
    </Helmet>

    <Seo title="Mechanism of action" description="See how AURYXIA works in the body. Read Important Safety Information and Prescribing Information on this page. See Prescribing Information and Full Safety Information." />
    <section id="hero" className="col-10 center">
      <div className="flex-row">
        <div>
          <h1>Mechanism of action of AURYXIA</h1>
          <p>Watch this video and see how AURYXIA, an oral iron tablet, works in adult patients.</p>

          <div className="video-wrap ida">
            {/* <VideoWithPlayButton
              accountId="5982844371001"
              playerId="eQq6v3QYZR"
              videoId="6189938842001"
              posterImg={videoPoster} /> */}
            <VideoWithPlayButton
              url = "https://www.youtube.com/embed/2trKJNelHYA"
              // width="100%" 
              // height="100%"
            />
          </div>
        </div>
      </div>
    </section>

    <section id="transcript">
      <TranscriptAccordion
        siteAudience="ida"
        transcriptTitle="View transcript"
        transcriptContent={<>
          <p>Chronic kidney disease, CKD, is characterized by a gradual loss of kidney function, causing insufficient synthesis of erythropoietin, a hormone that stimulates red blood cell (RBC) production and a lack of which causes anemia.<sup>1</sup> Iron deficiency also causes anemia in CKD patients.<sup>1</sup></p>
          <p>AURYXIA is a unique formulation of ferric citrate coordination complexes that treats iron deficiency anemia in adults with CKD not receiving dialysis.<sup>2-4</sup></p>
          <p>Upon swallowing AURYXIA, ferric iron is converted to ferrous iron by the enzyme ferric reductase in the gastrointestinal tract, where the molecules are absorbed in the same highly regulated physiological pathway as dietary iron.<sup>2,3,5</sup> In the bloodstream, ferrous iron is oxidized back to ferric iron, which binds to transferrin, an iron transfer protein that transports iron to the bone marrow for incorporation into hemoglobin for RBC production.<sup>2,3,5</sup></p>
          <p>AURYXIA treats iron deficiency anemia in non-dialysis patients with CKD.<sup>2,3</sup></p>
        </>} />
    </section>

    <section id="how-auryxia-works" className="col-10 center">
      <div>
        <div>
          <h2 className="ida">How AURYXIA works to treat iron deficiency anemia </h2>
          <p>AURYXIA is a unique formulation of ferric citrate coordination complexes that treats iron deficiency anemia in adults with CKD not receiving dialysis.<sup>2-4</sup></p>
          <div className="flex-row">
            <div>
              <DosingIconOne />
              <p>Ferric iron is converted to ferrous iron by the enzyme ferric reductase in the gastrointestinal tract<sup>2,3</sup></p>
              {/*  [Chapter link frame – 2]  */}
            </div>
            <div>
              <DosingIconTwo />
              <p>The molecules are absorbed in the same highly regulated physiological pathway as dietary iron<sup>2,3</sup></p>
              {/* [Chapter link frame – 3] */}
            </div>
            <div>
              <DosingIconThree />
              <p>In the bloodstream, ferrous iron is oxidized back to ferric iron, which binds to transferrin<sup>2,3</sup></p>
            </div>
            <div>
              <DosingIconFour />
              <p>Transferrin transports iron to the bone marrow for incorporation into hemoglobin for red blood cell production<sup>2,3</sup></p>
              {/* [Chapter link frame – 3] */}
            </div>
          </div>
        </div>
      </div>
      <p className="footnote center">CKD=chronic kidney disease.</p>
    </section>

    <EopCalloutCards
      siteAudience="ida"
      callOneClass={"highlight"}
      callOneLink={"/iron-deficiency-anemia/efficacy/"}
      callOneContent={
        <>
          <img src={CalloutIcon1} alt="" width="98" height="98" />
          <h3>See what AURYXIA can do for your&nbsp;patients</h3>
          <p>AURYXIA increased Hb and iron parameters (TSAT and ferritin) without IV iron or ESA use in a 16-week pivotal trial.<sup>1,2,4,5</sup></p>
          <button className="cta ida"><span>EXAMINE EFFICACY</span></button>
          <p className="footnote center">IV iron use and ESA use were not allowed per trial design and exclusion&nbsp;criteria.<sup>1</sup></p>
        </>
      }
      callTwoClass={"default"}
      callTwoLink={"/iron-deficiency-anemia/safety/adverse-reactions/"}
      callTwoContent={
        <>
          <img src={CalloutIcon2} alt="" width="98" height="98" />
          <h3>Get to know the AURYXIA safety profile</h3>
          <p>Explore the safety and tolerability profile evaluated in a pivotal clinical trial.<sup>2</sup></p>
          <button className="cta ida"><span>EXPLORE SAFETY</span></button>
        </>
      }
    />

    <section id="crosslink-slab" className="akb akb_moa_section">
      <div className="flex-row"
        data-sal="zoom-in"
        data-sal-duration="600"
        data-sal-delay="200"
        data-sal-ease="ease-out-expo"
      >
        <div>
          <img src={akbcares} alt="AkebiaCares" height="81" />
          <h3>Your partner in helping patients access the medication they need</h3>
          <p className="boldTxt">Connect with a personal Case Manager today!</p>
          <p className="med_width">AkebiaCares personal Case Managers are standing by live to answer your call and help your patients find coverage.
        </p>
          <ul className="info_ul">
            <li><a href="tel:18334253242">1&#xFEFF;-&#xFEFF;833&#xFEFF;-&#xFEFF;4AKEBIA&nbsp;(425&#xFEFF;-&#xFEFF;3242)</a></li>
            <li>Monday&nbsp;-&nbsp;Friday</li>
            <li className="hours_li">8<span>AM</span>&nbsp;–&nbsp;8<span>PM</span>&nbsp;EST</li>
          </ul>
          <a href="https://www.akebiacareshcp.com/" className="cta akb"><span>FIND SUPPORT OPTIONS</span></a>

        </div>
      </div>
    </section>

    <section id="interested-slab" className="col-6 center">
      <div className="flex-row" data-sal="slide-up"
        data-sal-duration="600"
        data-sal-delay="200"
        data-sal-ease="ease-out-expo"
      >

        <div>
          <h3>You may also be interested&nbsp;in: </h3>
          <p><Link to="/iron-deficiency-anemia/about-auryxia/patient-profile/" className="gradient-link ida">PATIENT PROFILE</Link></p>
          <p><Link to="/iron-deficiency-anemia/about-auryxia/resources/" className="gradient-link ida">RESOURCES</Link></p>
        </div>

      </div>
    </section>

  </IDALayout>

)

export default IdaMoaPage